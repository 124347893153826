import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import { media } from '../MediaQueries';
import Colors from '../Colors';
import Img from 'gatsby-image';
import Layout, { Content } from '../components/Layout';
import { graphql } from 'gatsby';
import { FaGithub, FaHeart, FaLinkedinIn } from 'react-icons/fa';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding: 140px 0 40px 0;
`;

const Block = styled.div`
  ${Mixins.block({})};
  fill: rgba(0, 0, 0, 0.7);
  & a {
    cursor: pointer;
  }
  & a:not(:last-child) {
    margin-right: 20px;
  }
  & svg:hover {
    fill: black;
  }
`;

const HomepageWrapper = styled.div`
  .who-desc {
    display: block;
    margin: 0 auto 60px auto;
    max-width: 90%;
  }
  .avatar {
    max-width: 200px;
    width: 170px;
    height: 170px;
    margin: 0 auto 50px auto;
    border-radius: 50%;
    display: block;
    box-shadow: white 0px 0px 0px 3px, rgb(204, 204, 204) 0px 3px 8px 3px;
  }
  .portfolio {
    margin: 100px 0 50px 0;
    font-size: 32px;
  }
`;

const Intro = styled.p`
  display: block;
  position: relative;
  margin-bottom: 25px;

  &::after {
    content: '◦';
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: -21.4px;
    color: rgb(204, 204, 204);
    font-size: 10px;
  }
`;

const ColoredLabel = styled.div`
  padding: 0.25em;
  border-right: 2px solid rgba(87, 199, 255, 0.3);
  border-left: 2px solid rgba(87, 199, 255, 0.3);
  display: inline-block;
  white-space: nowrap;
  color: inherit;
  line-height: 1;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  background-color: rgba(87, 199, 255, 0.1);
`;

const Projects = styled.ul`
  ${Mixins.block({ axis: 'column' })};
  max-width: 1000px;
  margin: 0 auto 60px;
  padding-left: 15px;
  ${media.phone`
    padding-left: 0;
  `}
}
`;

const ProjectItem = styled.li`
  ${Mixins.block({ axis: 'row' })}
  max-width: 500px;
  padding: 20px 0;

  ${media.phone`
    width: 90%;
  `}
`;

const ProjectImg = styled(Img)`
  width: 95px;
  border-radius: 7px;
`;

const ProjectDetails = styled.div`
  ${Mixins.block({ axis: 'column', align: 'flex-start' })}
  width: 360px;
  margin-left: 20px;
`;

const StackList = styled.ul`
  ${Mixins.block({ justify: 'flex-start' })}
  padding-left: 15px;
`;

const StackItem = styled.li`
  margin-right: 25px;
  font-size: 14px;
  color: ${Colors.darkGrey_1};
`;

class Homepage extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <HomepageWrapper>
        <Layout theme="white">
          <AboveFold>
            <Img fluid={data.avatarHomepage.childImageSharp.fluid} alt="Chanwhe Kim" className="avatar" />
            <t.H2 primary align="center">
              Chanwhe Kim
            </t.H2>
            <t.P align="center" max45>
              <ColoredLabel>Frontend developer</ColoredLabel>
              <ColoredLabel>Software engineer</ColoredLabel>
            </t.P>
          </AboveFold>
          <Content>
            <t.P align="center" max70>
              <Intro>
                I design applications, interactive systems and websites. I love using technology in creative contexts.
              </Intro>
              <Intro>Contact me at Chanwhe.kim@gmail.com</Intro>
            </t.P>
            <Block>
              <a href="https://github.com/chanwhekim">
                <FaGithub size={25} color="rgba(0, 0, 0, 0.7)" />
              </a>
              <a>
                <FaLinkedinIn size={25} color="rgba(0, 0, 0, 0.7)" />
              </a>
            </Block>
            <t.H2 primary align="center" className="portfolio">
              A selection of work
            </t.H2>
            <Projects>
              <ProjectItem>
                <ProjectImg fluid={data.watcha.childImageSharp.fluid} />
                <ProjectDetails>
                  <t.H4 noMargin>
                    WATCHA <FaHeart size={14} color="rgb(255, 92, 87)" />
                  </t.H4>
                  <t.P>Watcha providing Subscription VOD streaming service</t.P>
                </ProjectDetails>
              </ProjectItem>

              <ProjectItem>
                <ProjectImg fluid={data.alps.childImageSharp.fluid} />
                <ProjectDetails>
                  <t.H4 noMargin>Alps</t.H4>
                  <t.P>Loan counseling video call application for desktop, mobile</t.P>
                  <StackList>
                    <StackItem>WebRTC</StackItem>
                    <StackItem>Socket</StackItem>
                    <StackItem>React</StackItem>
                  </StackList>
                </ProjectDetails>
              </ProjectItem>

              <ProjectItem>
                <ProjectImg fluid={data.abTest.childImageSharp.fluid} />
                <ProjectDetails>
                  <t.H4 noMargin>Vanilla A/B Test</t.H4>
                  <t.P>A/B test for figuring out which landing page works best.</t.P>
                  <StackList>
                    <StackItem>Node.js</StackItem>
                    <StackItem>React</StackItem>
                    <StackItem>Redux</StackItem>
                    <StackItem>D3</StackItem>
                  </StackList>
                </ProjectDetails>
              </ProjectItem>
              <ProjectItem>
                <ProjectImg fluid={data.readit.childImageSharp.fluid} />
                <ProjectDetails>
                  <t.H4 noMargin>Readit</t.H4>
                  <t.P>Readit is the place where you can find insightful and original stories</t.P>
                  <StackList>
                    <StackItem>React</StackItem>
                    <StackItem>Redux</StackItem>
                    <StackItem>Node.js</StackItem>
                    <StackItem>Express</StackItem>
                  </StackList>
                </ProjectDetails>
              </ProjectItem>
            </Projects>
          </Content>
        </Layout>
      </HomepageWrapper>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    avatarHomepage: file(relativePath: { eq: "profile.jpg" }) {
      ...fluidImage
    }
    watcha: file(relativePath: { eq: "watcha.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    alps: file(relativePath: { eq: "alps.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    knowru: file(relativePath: { eq: "knowru.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    abTest: file(relativePath: { eq: "ab-test.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    readit: file(relativePath: { eq: "readit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
